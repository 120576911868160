<template>
  <div ref="permissionDeniedPage" aria-labelledby="permission denied page">
    <div class="flex justify-start">
      <div class="mx-2">
        <Button text="Log in again" size="small" @click="login()" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/reusable-components/base-level-components/Button";

export default {
  name: "Logout",
  components: {
    Button,
  },
  methods: {
    login: function () {
      window.location.href = process.env.VUE_APP_SSO_REDIRECT;
    },
  },
};
</script>
